const common = {
    setAccounts: accounts =>
        ({
            type: 'SET_ACCOUNTS',
            accounts,
        }),
    setAccountTransactions: (accountId, transactions, offset) =>
        ({
            type: 'SET_ACCOUNTS_TRANSACTIONS',
            accountId,
            transactions,
            offset,
        }),
    setActiveCurrency: currency =>
        ({
            type: 'SET_ACTIVE_CURRENCY',
            currency,
        }),
    setActiveAccount: accountId =>
        ({
            type: 'SET_ACTIVE_ACCOUNT',
            accountId,
        }),
    appendTransactions: (accountId, transactions, offset) =>
        ({
            type: 'APPEND_TRANSACTIONS',
            accountId,
            transactions,
            offset,
        }),
    setTokenData: tokenData =>
        ({
            type: 'SET_TOKEN_DATA',
            tokenData,
        }),
    setTokenId: tokenId =>
        ({
            type: 'SET_TOKEN_ID',
            tokenId,
        }),
    setAccessError: error =>
        ({
            type: 'ACCESS_ERROR',
            errorMessage: error,
        }),
    setDevKey: devKey =>
        ({
            type: 'SET_DEV_KEY',
            devKey,
        }),
    setWebAppEnabled: webAppEnabled =>
        ({
            type: 'SET_WEB_APP_ENABLED',
            webAppEnabled,
        }),
    setSourceBankId: bankId =>
        ({
            type: 'SET_SOURCE_BANK_ID',
            bankId,
        }),
    setMemberType: memberType =>
        ({
            type: 'SET_MEMBER_TYPE',
            memberType,
        }),
    setReceiveCallback: receiveCallback =>
        ({
            type: 'SET_RECEIVE_CALLBACK',
            receiveCallback,
        }),
    setSelectedBankInfo: bankInfo =>
        ({
            type: 'SET_SELECTED_BANK_INFO',
            bankInfo,
        }),
    setCredentials: credentials =>
        ({
            type: 'SET_CREDENTIALS',
            credentials,
        }),
};

export default common;
