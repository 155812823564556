import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from 'SimpleHistory';
import './index.css';
import MenuDrawer from 'components/MenuDrawer';
import logoFinvertex from 'assets/logo-finvertex.png';
import logoFinvertexMobile from 'assets/logo-finvertex-mobile.png';
import WebAppSelection from './WebAppSelection';

const Links = () => (
    <nav className="header-menu">
        <Link to="/" className="header-menu-item header-menu-item--active">HOME</Link>
        <a href="#" className="header-menu-item">BANKING</a>
        <a href="#" className="header-menu-item">CREDIT CARDS</a>
        <a href="#" className="header-menu-item">INVESTMENTS</a>
        <a href="#" className="header-menu-item">LOANS</a>
        <a href="#" className="header-menu-item">HELP</a>
        <a href="#" className="header-menu-item">CONTACT US</a>
        <a href="#" className="header-menu-item">LOCATIONS</a>
    </nav>
);

const Outer = ({children}) => (
    <div>
        <header>
            <div className="header-logo">
                <div className="header-wrapper">
                    <Link to="/">
                        <img src={logoFinvertex}
                            width="230" height="52" alt="Finvertex"
                            className="logo-desktop" />
                        <img src={logoFinvertexMobile}
                            width="111" height="18" alt="Finvertex"
                            className="logo-mobile" />
                    </Link>
                    { <WebAppSelection /> }
                </div>
            </div>
            <div className="menu-bg">
                <Links />
            </div>
            <MenuDrawer iconColor="#1DB8EA">
                <Links />
            </MenuDrawer>
        </header>
        <main role="main">
            <div className="wrapper">
                {children}
            </div>
        </main>
    </div>
);

Outer.propTypes = {
    children: PropTypes.node,
};

export default showIfLocation(Outer);
