import React from 'react';
import PropTypes from 'prop-types';
import {Link, showIfLocation} from 'SimpleHistory';
import {connect} from 'react-redux';
import './Error.css';

const Error = ({
    error,
}) => {
    const errorMessage = error === 'FAILURE_NO_ACCOUNTS'
        ? 'No accounts available for access'
        : error;
    return (
        <div className="Error">
            <h1 className="Error-title">Error on access request</h1>
            <div className="Error-desc">
                <p>
                    {`There was as error requesting access: ${errorMessage}.`}
                    <br />
                    {'Please '}
                    <Link to="/account">try again</Link>
                </p>
            </div>
        </div>
    );
};

Error.propTypes = {
    error: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        error: state.accessError,
    };
};

export default showIfLocation(connect(mapStateToProps)(Error));
