import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {withHistory} from 'SimpleHistory';
import Outer from 'components/Pages';
import Login from 'components/Pages/Login';
import Account from 'components/Pages/Account';
import Unpaired from 'components/Pages/Unpaired';
import Paired from 'components/Pages/Paired';
import Error from 'components/Pages/Error';
import {IntlProvider} from 'react-intl';
import {hotModule} from 'config/constants';

class App extends React.Component {
    render() {
        return (
            <Provider store={this.props.store}>
                <IntlProvider locale="en">
                    <Outer path={/^\//}>
                        <Login path={/^\/$/}></Login>
                        <Account path={/^\/account/}>
                            <Unpaired path={/^\/account$/}></Unpaired>
                            <Paired path={/^\/account\/paired/}></Paired>
                            <Error path={/^\/account\/error/}></Error>
                        </Account>
                    </Outer>
                </IntlProvider>
            </Provider>
        );
    }
}

App.propTypes = {
    history: PropTypes.object,
    store: PropTypes.object,
};

App.defaultProps = {
    store: PropTypes.object,
};

const HistoryApp = withHistory(App);

export default hotModule
    && require('react-hot-loader').hot(module)(HistoryApp)
    || HistoryApp;
