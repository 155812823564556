import React from 'react';
import style from './SmallSpinner.css';
import { SpinnerSmall } from '@token-io/lib-web-components/src/Components/Spinner';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(style);

export const SmallSpinner = ({className, color}) => (
    <div
        className={cx(
            {
                TextInput__inner__loader: true,
                Custom_loader: true,
                [className]: !!className,
            },
        )}
    >
        <SpinnerSmall
            className={cx(
                {
                    Custom_spinner: true,
                },
            )}
            color={color || '#2196F3'}
        />
    </div>
);

SmallSpinner.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

export default SmallSpinner;
