import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from './WebAppSelection.css';
import classNames from 'classnames/bind';
import Credentials from './Credentials';
import TextInput from './TextInput';
import {validateCredentials, fetchBankInfo} from 'util.js';
import commonActions from 'actions/commonActions';
import {TOKEN_API_URL} from 'config/constants';

const cx = classNames.bind(style);

class WebAppSelection extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            bankId: {
                isValid: true,
            },
            devKey: '',
        };
        this.confirming = false;
        this.confirm = this.confirm.bind(this);
    }

    confirm(e) {
        e && e.preventDefault();
        const { store } = this.context;
        const {customSource} = store.getState();
        store.dispatch(commonActions.setMemberType(this.getMemberType()));
        store.dispatch(commonActions.setSourceBankId(this.state.bankId?.value));
        store.dispatch(commonActions.setWebAppEnabled(this.state.webAppEnabled));
        store.dispatch(commonActions.setReceiveCallback(this.state.receiveCallback));
        store.dispatch(commonActions.setDevKey(this.state.devKey));
        store.dispatch(commonActions.setCredentials(
            validateCredentials(
                this.state.credentials,
                customSource.selectedBank?.credentialFields,
                this.state.bankId?.value,
                this.state.isType2Member && !this.state.webAppEnabled,
            ),
        ));
        localStorage.setItem('dk', this.input.value);
        this.setState({ isOpen: false });
    }

    getMemberType() {
        if (!this.state.webAppEnabled && this.state.isType2Member) {
            if (this.state.receiveCallback) {
                return 'type2_callback';
            } else {
                return 'type2';
            }
        } else {
            return 'type1';
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState?.bankId?.value?.trim() !== this.state.bankId?.value?.trim())
            || (prevState?.devKey?.trim() !== this.state.devKey?.trim())) {
            this.handleBankId();
        }
    }

    handleBankId = () => {
        const { store } = this.context;
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            const bankInfo = TOKEN_API_URL + '/banks?ids=' + this.state.bankId?.value?.trim();
            fetchBankInfo(bankInfo, this.state.devKey)
                .then(res => {
                    if (res.status === 200) {
                        const bankInfo = res.data?.banks?.[0];
                        if (bankInfo) {
                            store.dispatch(commonActions.setSelectedBankInfo(bankInfo));
                            this.setState({
                                displaySpinner: false,
                                bankId: {
                                    ...this.state.bankId,
                                    isValid: true,
                                },
                            });
                        } else {
                            store.dispatch(commonActions.setSelectedBankInfo());
                            this.setState({
                                displaySpinner: false,
                                bankId: {
                                    ...this.state.bankId,
                                    isValid: false,
                                },
                            });
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.error('Get banks api fails with status', res.status, res.data);
                        store.dispatch(commonActions.setSelectedBankInfo());
                        this.setState({
                            displaySpinner: false,
                            bankId: {
                                ...this.state.bankId,
                                isValid: false,
                            },
                        });
                    }
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(
                        'Get banks api fails with status',
                        error.response?.status,
                        error.response?.data,
                    );
                    store.dispatch(commonActions.setSelectedBankInfo());
                    this.setState({
                        displaySpinner: false,
                        bankId: {
                            ...this.state.bankId,
                            isValid: false,
                        },
                    });
                });
        }, 700);
    }

    setCredentials = credentials => {
        this.setState({
            credentials: {
                ... this.state.credentials,
                ... credentials,
            },
        });
    }

    componentDidMount() {
        const { store } = this.context;
        const state = store.getState();
        const { webAppEnabled, memberType, receiveCallback, source, credentials } =
            state.customSource;
        const { devKey } = state;
        this.setState({
            webAppEnabled: webAppEnabled !== undefined ? webAppEnabled : true,
            isType2Member: memberType && memberType.substring(0, 5) === 'type2' || false,
            receiveCallback: receiveCallback || false,
            bankId: {
                value: source && source.bankId || '',
                isValid: this.state.bankId?.isValid,
            },
            devKey: devKey,
            credentials: credentials,
        });
    }

    getRequiredCreds = () => {
        const { store } = this.context;
        const { customSource } = store.getState();
        return this.state.isType2Member &&
            !this.state.webAppEnabled &&
            customSource.selectedBank?.credentialFields ||
            null;
    }

    render() {
        const requiredCredentials = this.getRequiredCreds();
        const inner = !this.state.isOpen
            ? (<p>Configuration</p>)
            : (
                <div className={'WebAppSelection-subcontainer'}>
                    <span id="Label-span">Bank-id</span>
                    <form id={'CustomInput-form'} onSubmit={e => this.confirm(e)}>
                        <TextInput
                            onKeyDown={e => e.key === 'Enter' && this.confirm(e)}
                            value={this.state.bankId?.value}
                            defaultValue={this.state.bankId?.value}
                            id={'BankId-input'}
                            onChange={e =>
                                this.setState({
                                    displaySpinner: true,
                                    bankId: {
                                        ...this.state.bankId,
                                        value: e.target.value,
                                    },
                                })}
                            displaySpinner={this.state.displaySpinner}
                            className={cx({
                                'Invalid_input': !this.state.bankId?.isValid &&
                                    !this.state.displaySpinner &&
                                    this.state.bankId.value,
                            })}
                            placeholder={'Enter the Bank Id'}
                            autoFocus />

                        <Credentials
                            onKeyDown={e => e.key === 'Enter' && this.confirm()}
                            requiredCredentials={requiredCredentials}
                            setCredentials={this.setCredentials}
                            credentialsValue={this.state.credentials} />
                    </form>
                    <span id="Label-span">Additional field (optional)</span>
                    <TextInput
                        onKeyDown={e => e.key === 'Enter' && this.confirm(e)}
                        placeholder={'Enter Dev Key (Optional)'}
                        ref={input => this.input = input}
                        value={this.state.devKey}
                        onChange={e => this.setState({ devKey: e.target.value })} />

                    <div className={'Config-option Config-webAppEnabled'}>
                        <button className={this.state.webAppEnabled && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                webAppEnabled: true,
                            })}>
                            {'Use Web-App'}
                        </button>
                        <button className={!this.state.webAppEnabled && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                webAppEnabled: false,
                            })}>
                            {'Don\'t use Web-App'}
                        </button>
                    </div>
                    {!this.state.webAppEnabled &&
                        <div className={'Config-option Config-webAppEnabled'}>
                            <button className={!this.state.isType2Member && 'set' || ''}
                                onClick={() =>
                                    this.setState({
                                        ...this.state,
                                        isType2Member: false,
                                    })}>
                                {'Type 1'}
                            </button>
                            <button className={this.state.isType2Member && 'set' || ''}
                                onClick={() =>
                                    this.setState({
                                        ...this.state,
                                        isType2Member: true,
                                    })}>
                                {'Type 2'}
                            </button>
                        </div>
                    }
                    {!this.state.webAppEnabled && this.state.isType2Member &&
                        <div className={'Config-option Config-webAppEnabled'}>
                            <button className={this.state.receiveCallback && 'set' || ''}
                                onClick={() => this.setState({
                                    ...this.state,
                                    receiveCallback: true,
                                })}>
                                {'TPP Callback'}
                            </button>
                            <button className={!this.state.receiveCallback && 'set' || ''}
                                onClick={() => this.setState({
                                    ...this.state,
                                    receiveCallback: false,
                                })}>
                                {'Token Callback'}
                            </button>
                        </div>
                    }
                    <div className={'Config-control'}>
                        <div>
                            <button
                                className={'Config-submit'}
                                form={'CustomInput-form'}
                                disabled={!!this.state.displaySpinner}>
                                {'Confirm'}
                            </button>
                        </div>
                    </div>
                </div>
            );
        return (
            <div className={'WebAppSelection-container'}
                onClick={() => !this.state.isOpen && this.setState({isOpen: !this.state.isOpen})}>
                {inner}
            </div>
        );
    }
}

WebAppSelection.contextTypes = {
    store: PropTypes.object,
};

export default WebAppSelection;
