import axios from 'axios';
import { version } from './config/constants';
import { v4 as uuidv4 } from 'uuid';

const aispInstance = axios.create({
    baseURL: window.location.origin,
});

export const AispClient = {
    getAispAlias: memberType => {
        return new Promise(resolve => {
            aispInstance({
                method: 'post',
                url: '/aisp',
                data: {memberType: memberType},
            }).then(resp => {
                resolve( resp.data.alias );
            });
        });
    },
    getTokenRequestUrl: async (devKey, memberType, webAppEnabled, sourceAccount) => {
        const result = await aispInstance({
            method: 'post',
            url: '/getTokenRequestUrl',
            data: {devKey, memberType, webAppEnabled, sourceAccount},
        });
        return result.data;
    },
    pullAuthorizedData: req => {
        return aispInstance({
            method: 'post',
            url: '/getUserData',
            data: req,
        }).then(res => res.data);
    },
    pullAccountInfo: req => {
        return aispInstance({
            method: 'post',
            url: '/getAccountInfo',
            data: req,
        }).then(res => res.data);
    },
    pullTransactions: (
        tokenData,
        accountId,
        offset,
        startDate,
        endDate,
        customerTrackingMetadata,
    ) => {
        return aispInstance({
            method: 'post',
            url: '/getTransactions',
            data: {
                tokenId: tokenData['tokenId'],
                requestId: tokenData['request-id'],
                accountId,
                offset,
                startDate,
                endDate,
                customerTrackingMetadata,
            },
        }).then(res => res.data);
    },
};

export const filterByCurrency = (a, currency) => {
    let accounts = [];
    a.map((acc, i) => {
        if (acc.balance.current.currency === currency) {
            acc.index = i;
            accounts.push(acc);
        }
    });
    const total = accounts.reduce((lastVal, acc) =>
        lastVal + parseFloat(acc.balance.current.value)
    , 0.0);
    accounts = accounts.map(acc => {
        acc.percentage = acc.balance.current.value > 0
            ? Math.round(parseFloat(acc.balance.current.value) / total * 100)
            : 0;
        return acc;
    });
    return {
        accounts,
        total,
        currency,
    };
};

export const getAvailableCurrencies = a => Array.from(new Set(
    a.map(acc => acc.balance.current.currency),
));

export const responsive = w => ({phone, tablet, def}) => {
    switch (true) {
        case w < 640:
            return phone;
        case w < 960:
            return tablet;
        default:
            return def;
    }
};

export const getPluralizedString = (count, string, suffix = 's') =>
    `${count} ${string}${count !== 1 ? suffix : ''}`;

export const sortByKey = (arr, key) => arr.sort((a, b) => {
    const x = a[key];
    const y = b[key];
    return ((x < y) ? 1 : ((x > y) ? -1 : 0));
});

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (!serializedState) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors.
    }
};

export const syncLocalStorageVersion = () => {
    const localStorageVersion = localStorage.getItem('version');
    if (!localStorageVersion || localStorageVersion !== version) {
        localStorage.clear();
        localStorage.setItem('version', version);
        location.reload();
    }
};

export const getDeviceId = () => {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
};

export const getUserAgent = () => {
    return window.navigator.userAgent;
};

export const validateCredentials = (
    actualCredentials,
    requiredCredentials,
    bankId,
    preCondition,
) => {
    if (bankId === '' || !actualCredentials || !preCondition) {
        return undefined;
    }
    const actualKeys = [];
    if (requiredCredentials) {
        requiredCredentials.map(data => actualKeys.push(data.id));
        const credentials = Object.keys(actualCredentials)
            .filter(k => actualKeys.includes(k))
            .reduce((res, key) => (res[key] = actualCredentials[key], res), {});
        return credentials;
    } else {
        return undefined;
    }
};

export const fetchBankInfo = async (url, devKey) => {
    return await axios.get(`${url}`, {
        headers: {'token-dev-key': devKey ? devKey.trim() : 'global-test'},
    });
};
