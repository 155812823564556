import React from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Link, showIfLocation} from 'SimpleHistory';
import './Account.css';
import 'assets/breadcrumbs-divider.png';

class Account extends React.Component {
    getChildren() {
        const {history} = this.props;
        const path = history.location.pathname;
        return this.props.children &&
        <CSSTransition
            key={path}
            classNames="pageFade"
            timeout={{enter: 800, exit: 800}}>
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        </CSSTransition>;
    }
    render() {
        return (
            <div className="Account">
                <nav className="Breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/account" className="active">My Account</Link>
                </nav>
                <TransitionGroup>
                    {this.getChildren()}
                </TransitionGroup>
            </div>
        );
    }
}

Account.propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
};

export default showIfLocation(Account);
