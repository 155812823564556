let browserEnv = {};

// Allow configuration of environment variables through browser environment
if (window !== undefined && window.BROWSER_ENV !== undefined) {
    browserEnv = window.BROWSER_ENV;
}
export const TOKEN_ENV = browserEnv.tokenEnv;

export const hotModule = browserEnv.hotModule;

export const WEB_APP_ENV = browserEnv.webAppEnv || TOKEN_ENV;

export const currencySymbols = browserEnv.currencySymbols;

export const DEV_KEY = 'd3b08f1f-83d9-4969-98f0-73c887e95a8d';

export const transactionsLimit = browserEnv.transactionsLimit;

export const version = '2.0';

export const TOKEN_API_URL = TOKEN_ENV !== 'prd' ?
    `https://api.${TOKEN_ENV}.token.io` :
    'https://api.token.io';
