import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import {AispClient} from 'util.js';
import commonActions from 'actions/commonActions';
import {withHistory} from 'SimpleHistory';
import {WEB_APP_ENV} from 'config/constants';

const SCRIPT_INTERVAL = 30;

class TokenEnablerButton extends React.Component {
    constructor(props) {
        super(props);
        this.addAccessButton = this.addAccessButton.bind(this);
    }
    componentDidMount() {
        this.addAccessButton();
    }
    componentDidUpdate() {
        this.addAccessButton();
    }

    // Retries adding the access button until window.Token is defined (token.js loaded)
    async addAccessButton() {
        const {
            devKey,
            memberType,
            sourceAccount,
            webAppEnabled,
            text,
            onError,
            credentials,
        } = this.props;

        const {store} = this.context;

        if (!window.Token) {
            setTimeout(
                this.addAccessButton,
                SCRIPT_INTERVAL);
            return;
        }

        const token = new window.Token({
            env: WEB_APP_ENV,
        });

        // destroy previous button instance
        if (this.button) {
            this.button.destroy();
        }

        // create button instance
        this.button = token.createTokenButton(this.buttonRef, {
            label: text,
            hideLogo: true,
        });

        // create TokenController to handle messages
        this.tokenController = token.createController({
            onSuccess: data => { // Success Callback
                store.dispatch(commonActions.setTokenData(data));
                window.document.location.assign('/account/paired');
            },
            onError: error => { // Failure Callback
                onError(error.error + ': ' + error.message);
                window.document.location.assign('/account/paired');
                throw error;
            },
        });

        // bind the Token Button to the Token Controller when ready
        this.tokenController.bindButtonClick(
            this.button, // Token Button
            token.createRequest(
                redirect => { // redirect
                    redirect('/getTokenRequestUrl', {
                        devKey,
                        webAppEnabled,
                        sourceAccount,
                        memberType,
                        credentials,
                    });
                },
                async (done, errorCb) => { // optional async request for popup
                    try {
                        const tokenRequestUrl =
                            await AispClient.getTokenRequestUrl(
                                devKey,
                                memberType,
                                webAppEnabled,
                                sourceAccount,
                            );
                        done(tokenRequestUrl);
                    } catch (error) {
                        // execute error callback
                        errorCb('Error fetching Token Request URL');
                    }
                },
            ),
            async error => { // bindComplete callback
                if (error) throw error;
                // enable button after binding
                await AispClient.getAispAlias(memberType);
                this.button.enable();
            },
            { // options
                desktop: webAppEnabled && 'POPUP' || 'REDIRECT',
            },
        );
    }

    render() {
        return (
            <div id="myTokenButton" className="TokenEnablerPlaceholder" ref={ref => {
                this.buttonRef = ref;
            }}>
            </div>
        );
    }
}

TokenEnablerButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    devKey: PropTypes.string,
    credentials: PropTypes.object,
    memberType: PropTypes.string.isRequired,
    sourceAccount: PropTypes.object,
    webAppEnabled: PropTypes.bool.isRequired,
    onError: PropTypes.func,
    history: PropTypes.object,
};

TokenEnablerButton.defaultProps = {
    text: 'Enable Token',
    webAppEnabled: true,
    onError: () => {},
};

TokenEnablerButton.contextTypes = {
    store: PropTypes.object,
};

export default withHistory(TokenEnablerButton);
