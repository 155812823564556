import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';

class AccountItem extends React.Component {
    constructor() {
        super();
    }

    render() {
        const {opacity, color, logo, acc, amount, enabled, currentAccount} = this.props;
        return (
            <div className="Account-item"
                style={{
                    transition: 'opacity 350ms',
                    opacity,
                }}>
                <div
                    className="Account-item-stripe"
                    style={{
                        transition: 'background-color 350ms',
                        backgroundColor: color,
                    }}>
                </div>
                <div className="Account-item-logo">
                    <span
                        className="AccountMenu-item-logo-image"
                        style={{
                            backgroundImage: `url(${logo})`,
                        }}>
                    </span>
                </div>
                <div className="Account-item-details">
                    <div
                        className="Account-item-bank">
                        {acc.bankName || acc.bankId}
                    </div>
                    <div className="Account-item-type">
                        {acc.name}
                    </div>
                    <div
                        className={'Account-item-balance' +
                        (amount < 0 ? ' Account-item-balance-negative' : '')}>
                        <FormattedNumber
                            style="currency"
                            currency={acc.balance.current.currency}
                            value={amount || 0}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2} />
                    </div>
                </div>
                {enabled && currentAccount ? (
                    <div className="Account-item-percentage">
                        {currentAccount.percentage || 0}
                        <span className="Account-item-percentage-sign">
                            %
                        </span>
                    </div>
                ) : ''}
            </div>
        );
    }
}

AccountItem.propTypes = {
    acc: PropTypes.object.isRequired,
    opacity: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    currentAccount: PropTypes.object.isRequired,
};

export default AccountItem;
