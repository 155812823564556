/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';
import {
    AispClient,
    filterByCurrency,
    getAvailableCurrencies,
    getDeviceId,
    getUserAgent,
} from 'util.js';
import {showIfLocation} from 'SimpleHistory';
import commonActions from 'actions/commonActions';
import TokenChart from 'components/Pages/TokenChart';
import throttle from 'lodash/throttle';
import Transactions from 'components/Pages/Transactions';
import ironLogo from 'assets/iron-logo.png';
import AccountItem from './AccountItem';

const AccountControls = ({
    currencies,
    activeCurrency,
    onCurrencyChange,
    updateChart,
}) => (
    <div className="Account-controls">
        <div className="Account-currency-selector">
            <span>
                {'Show Accounts '}
            </span>
            <select
                defaultValue={activeCurrency}
                onChange={e => {
                    onCurrencyChange(e.target.value);
                }}>
                {currencies.map(curr => (
                    <option
                        key={curr}
                        value={curr}>
                        {curr}
                    </option>
                ))}
            </select>
        </div>
        <div className="Account-refresh">
            <button
                onClick={updateChart}>
                    Refresh
            </button>
        </div>
    </div>
);

AccountControls.propTypes = {
    onCurrencyChange: PropTypes.func,
    updateChart: PropTypes.func,
    currencies: PropTypes.array,
    activeCurrency: PropTypes.string,
};

class Paired extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.updateChart = this.updateChart.bind(this);
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.onActiveAccountChange = this.onActiveAccountChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onStartDateSelect = this.onStartDateSelect.bind(this);
        this.drawChart = this.drawChart.bind(this);
        this.accounts = [];
        this.state = {
            isLoading: false,
            startDate: '',
            endDate: '',
            selectedStartDate: '',
        };
    }
    drawChart() {
        if (!this.accounts.length) return;
        this.tokenPie.update(this.accounts);
    }
    componentDidMount() {
        this._isMounted = true;
        const { store } = this.context;
        this.tokenPie = new TokenChart.PieChart(this.accountGraph, this.accounts);
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate();
            const { accounts, activeCurrency } = store.getState();
            if (!accounts.length) return;
            const enabledAccounts = filterByCurrency(accounts, activeCurrency);
            this.accounts = enabledAccounts.accounts;
            this.drawChart();
        });
        this.updateChart();
        window.addEventListener('resize', throttle(this.drawChart, 500));
    }
    componentWillUnmount() {
        this.unsubscribe();
        window.removeEventListener('resize', this.drawChart);
        this._isMounted = false;
    }
    onCurrencyChange(cur) {
        const { store } = this.context;
        store.dispatch(commonActions.setActiveCurrency(cur));
    }
    onActiveAccountChange(accountId) {
        const { store } = this.context;
        const { accounts } = store.getState();
        const account = accounts.find(acc => acc.id === accountId);
        if (!account) return;
        store.dispatch(commonActions.setActiveAccount(accountId));
    }

    onDateChange = e => {
        this.setState({
            startDate: e.target.startDate.value,
            endDate: e.target.endDate.value,
        });
    }

    onStartDateSelect = startDate => {
        this.setState({
            selectedStartDate: startDate,
        });
    }

    async updateChart() {
        const {store} = this.context;
        const {tokenData, customSource} = store.getState();
        const {devKey} = store.getState();
        this.setState({isLoading: true});
        try {
            const customerTrackingMetadata = {deviceId: getDeviceId(), userAgent: getUserAgent()};
            // Fetch authorized data
            const { accountIds, tokenId, memberType } =
            await AispClient.pullAuthorizedData({
                ...tokenData,
                bankId: customSource?.selectedBank?.id || customSource?.source?.bankId,
                devKey: devKey,
                customerTrackingMetadata: customerTrackingMetadata,
            });

            // Use a Set to remove duplicate accountIds
            const uniqueAccountIds = Array.from(new Set(accountIds));

            // Update store with memberType and tokenId
            memberType && store.dispatch(commonActions.setMemberType(memberType));
            tokenId && store.dispatch(commonActions.setTokenId(tokenId));

            const batchSize = 20;
            let startIndex = 0;
            const totalAccounts = uniqueAccountIds.length;
            let allAccounts = [];

            while (startIndex < totalAccounts) {
                const endIndex = Math.min(startIndex + batchSize, totalAccounts);
                const batchAccountIds = uniqueAccountIds.slice(startIndex, endIndex);

                // Fetch accountInfo for the current batch
                const batchAccountPromises = batchAccountIds.map(async accountId => {
                    try {
                        const { accountInfo } = await AispClient.pullAccountInfo({
                            accId: accountId,
                            tokenId,
                            customerTrackingMetadata: customerTrackingMetadata,
                            devKey: devKey,
                        });

                        return accountInfo;
                    } catch (error) {
                    // Handle errors for individual account requests if needed
                        console.error(`Error fetching account info for accountId ${accountId}:`, error);
                    }
                });

                // Resolve promises for the current batch all together
                const batchAccounts = await Promise.all(batchAccountPromises);

                const accountsWithBalance = batchAccounts.filter(acc => acc && acc.balance);

                allAccounts = [...allAccounts, ...accountsWithBalance];
                store.dispatch(commonActions.setAccounts([...allAccounts]));

                startIndex += batchSize;
            }

            if (this._isMounted) {
                this.setState({isLoading: false});
            }
        } catch (e) {
            window.document.location.assign('/account/error');
            throw e;
        }
    }
    render() {
        const {store} = this.context;
        const {accounts, activeCurrency, activeAccountId} = store.getState();
        const currencies = getAvailableCurrencies(accounts);
        const enabledAccounts = filterByCurrency(accounts, activeCurrency);
        const isReady = !this.state.isLoading;
        const accountControls = (<AccountControls
            onCurrencyChange={this.onCurrencyChange}
            updateChart={this.updateChart}
            currencies={currencies}
            activeCurrency={activeCurrency}
        />);
        return (
            <div>
                <div className="Account-panel">
                    <div className="Account-header">
                        <div className="Account-title">
                            <h3>Accounts{!isReady ? <small>…loading</small> : ''}</h3>
                        </div>
                        {isReady ? accountControls : null}
                    </div>
                    <div className="Account-body">
                        <div className="Paired-panel-graph"
                            ref={accountGraph => (this.accountGraph = accountGraph)}>
                        </div>
                        {isReady ? accountControls : null}
                        <div className="Accounts-list">
                            {accounts.map((acc, index) => {
                                const enabled = acc.balance.current.currency === activeCurrency;
                                const opacity = enabled ? 1.0 : 0.35;
                                const color = enabled
                                    ? TokenChart.getColor(index)
                                    : 'transparent';
                                const logo = acc.bankLogo || ironLogo;
                                const amount = acc.balance.current.value;
                                const currentAccount = enabledAccounts.accounts.find(
                                    current => current.id === acc.id,
                                );
                                return (
                                    <AccountItem
                                        key={index}
                                        acc={acc}
                                        index={index}
                                        opacity={opacity}
                                        color={color}
                                        logo={logo}
                                        amount={amount}
                                        enabled={enabled}
                                        currentAccount={currentAccount}
                                    />
                                );
                            })}
                            {enabledAccounts.accounts.length ? (
                                <div className="Account-totals">
                                    <span className="Account-totals-label">Account Totals (
                                        <strong>{` ${enabledAccounts.currency} `}</strong>
                                    )
                                    </span>
                                    <span className="Account-totals-amount">
                                        <FormattedNumber
                                            value={enabledAccounts.total || 0}
                                            style="currency"
                                            currency={enabledAccounts.currency}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2} />
                                    </span>
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
                <Transactions
                    accounts={accounts}
                    onActiveAccountChange={this.onActiveAccountChange}
                    activeAccountId={activeAccountId}
                    onDateChange={this.onDateChange}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onStartDateSelect={this.onStartDateSelect}
                    selectedStartDate={this.state.selectedStartDate}
                />
            </div>
        );
    }
}

Paired.propTypes = {
    history: PropTypes.object,
};

Paired.contextTypes = {
    store: PropTypes.object,
};

export default showIfLocation(Paired);
