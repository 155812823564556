import {responsive} from 'util.js';

class PieChart {
    constructor(el, data) {
        if (!window.d3) throw new Error('D3.js is required.');
        this.buildPieStructure = this.buildPieStructure.bind(this);
        this.update = this.update.bind(this);
        this.data = data;
        this.d3 = window.d3;
        this.el = el;
        if (this.data) {
            this.update(this.data);
        }
    }
    buildPieStructure(el) {
        el.innerHTML = '';
        const size = el.getBoundingClientRect();
        const r = responsive(this.screenWidth);

        this.width = size.width;
        this.height = size.height - r({
            phone: 10,
            tablet: 10,
            def: 20,
        });
        this.radius = Math.min(this.width, this.height) / 2;

        this.color = this.d3.scale.category20();

        this.pie = this.d3.layout.pie()
            .sort(null)
            .value(d => d.value);

        this.arc = this.d3.svg.arc()
            .innerRadius(this.radius * r({
                phone: .30,
                tablet: .30,
                def: .25,
            }))
            .outerRadius(this.radius * r({
                phone: .82,
                tablet: .82,
                def: .65,
            }));

        this.svg = this.d3.select(el).append('svg')
            .attr('width', this.width)
            .attr('height', this.height)
            .append('g')
            .attr('transform', 'translate(' + this.width / 2 + ',' + this.height / 2 + ')');
    }
    update(data) {
        this.screenWidth = window.innerWidth;
        const dataSet = data
            .filter(acc => parseFloat(acc.balance.current.value, 10) > 0)
            .map(acc => ({
                index: acc.index,
                accountName: acc.name,
                bankName: acc.bankName || acc.bankId,
                value: parseFloat(acc.balance.current.value, 10),
            }));

        this.buildPieStructure(this.el);

        this.piedata = this.pie(dataSet);

        let pathGroup = this.svg.select('g.piePaths');
        if (pathGroup.empty()) {
            pathGroup = this.svg.append('g')
                .attr('class', 'piePaths');
        }

        this.path = pathGroup.selectAll('path.pie')
            .data(this.piedata);

        this.path.enter().append('path')
            .attr('class', 'pie')
            .attr('fill', d => {
                return getColor(d.data.index);
            })
            .attr('d', this.arc);

        this.path.exit()
            .remove();
    }
}

if (!window.d3) throw new Error('D3.js is required.');
const d3color = window.d3.scale.category20();

const colors = [
    '#f37d6e',
    '#8a67ff',
    '#20bf87',
    '#f3c26e',
];

const getColor = i => {
    return colors[i] || d3color(i - colors.length);
};

export default {
    PieChart: PieChart,
    getColor: getColor,
};
