import React from 'react';
import {Link, showIfLocation} from 'SimpleHistory';
import './Login.css';

const Login = () => (
    <div className="Login">
        <div className="Login-form">
            <h2 className="Login-form-title">Account Log In</h2>
            <div className="Login-form-fieldset">
                <div className="Login-form-fieldset-field">
                    <label className="Login-form-fieldset-field-label">E-Mail</label>
                    <input
                        className="Login-form-fieldset-field-email"
                        type="email"
                        placeholder="e.g. name@server.com"
                        defaultValue="account@gmail.com" />
                </div>
                <div className="Login-form-fieldset-field">
                    <label
                        className="Login-form-fieldset-field-label">
                        Password
                    </label>
                    <input
                        className="Login-form-fieldset-field-password"
                        type="password"
                        defaultValue="1234567890" />
                </div>
            </div>
            <Link to="/account" className="Login-form-submit">LOGIN</Link>
            <div className="Login-form-link">
                <a href="#">Forgot Password?</a>
            </div>
        </div>
    </div>
);

export default showIfLocation(Login);
