import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Transactions.css';
import TokenChart from 'components/Pages/TokenChart';
import {AispClient, getPluralizedString, getDeviceId, getUserAgent} from 'util.js';
import {FormattedNumber} from 'react-intl';
import {currencySymbols, transactionsLimit} from 'config/constants';
import commonActions from 'actions/commonActions';
import throttle from 'lodash/throttle';
import ironLogo from 'assets/iron-logo.png';

const TransactionsListControls = ({
    accounts,
    activeAccountId,
    onActiveAccountChange,
    onDateChange,
    onStartDateSelect,
    selectedStartDate,
}) => (
    <div className="Controls">
        <div className="Account-controls">
            <div className="Account-selector">
                <span className="span-block">
                    {'Show Transactions '}
                </span>
                <select
                    className={'Account-controls-selector'}
                    defaultValue={activeAccountId}
                    onChange={e => {
                        onActiveAccountChange(e.target.value);
                    }}>
                    {accounts.map(acc => {
                        return (
                            <option
                                key={acc.id}
                                value={acc.id}>
                                {acc.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
        <div className="Date-controls">
            <div className="Date-selector">
                <form onSubmit={e => {
                    e.preventDefault();
                    onDateChange(e);
                }}>
                    <span className="display-block-600">
                        <span className="span-block">
                            {'Start Date: '}
                        </span>
                        <input
                            type="date"
                            name="startDate"
                            id="startDate"
                            max={(new Date()).toISOString().split('T')[0]}
                            onChange={e => {
                                onStartDateSelect(e.target.value);
                            } }
                        />
                    </span>
                    <span className="display-block-600">
                        <span className="span-block">
                            {'End Date(optional): '}
                        </span>
                        <input
                            type="date"
                            name="endDate"
                            id="endDate"
                            min={selectedStartDate}
                        />
                    </span>
                    <button type="submit" value="Submit">Submit</button>
                </form>
            </div>
        </div>
    </div>
);

TransactionsListControls.propTypes = {
    onActiveAccountChange: PropTypes.func,
    accounts: PropTypes.array,
    activeAccountId: PropTypes.string,
    onDateChange: PropTypes.func,
    onStartDateSelect: PropTypes.func,
    selectedStartDate: PropTypes.string,
};

class TransactionsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        };
        this.onScroll = throttle(this.onScroll.bind(this), 500);
        this.fetchTransactions = this.fetchTransactions.bind(this);
    }
    componentDidMount() {
        const {store} = this.context;
        this.cancelFetch = false;
        this.unsubscribe = store.subscribe(() => {
            this.forceUpdate();
        });
        window.addEventListener('scroll', this.onScroll, false);
    }
    componentWillUnmount() {
        this.unsubscribe();
        this.cancelFetch = true;
        window.removeEventListener('scroll', this.onScroll, false);
    }
    componentDidUpdate(prevProps) {

        let isDateChanged = false;
        if (this.props.startDate !== prevProps.startDate
            || this.props.endDate !== prevProps.endDate)
        {
            isDateChanged = true;
        }
        if (this.props.activeAccountId !== prevProps.activeAccountId
            || isDateChanged)
        {
            this.fetchTransactions(this.props.activeAccountId, this.props.startDate,
                this.props.endDate, false, isDateChanged);
        }
    }

    async fetchTransactions(accountId,
        startDate = '',
        endDate = '',
        onScroll = false,
        isDateChanged = false) {
        const isDateRangeSelected = (startDate !== '') ? true : false;
        if (this.state.isLoading) return;
        const {activeAccountId} = this.props;
        const {store} = this.context;
        const {tokenData, transactions} = store.getState();
        const accountTransactions = transactions[activeAccountId] && transactions[activeAccountId];
        const offset = !isDateChanged && accountTransactions && accountTransactions.offset || '';
        const customerTrackingMetadata = {deviceId: getDeviceId(), userAgent: getUserAgent()};
        if (!isDateRangeSelected && offset === 'EOL') return;
        this.setState({isLoading: true});
        try {
            const transactions = await AispClient.pullTransactions(
                tokenData,
                accountId,
                offset,
                startDate,
                endDate,
                customerTrackingMetadata);
            !this.cancelFetch && this.setState({isLoading: false});
            if (!transactions) {
                return;
            }
            const newOffset = transactions.transactions.length
                && transactions.transactions.length === transactionsLimit
                && transactions.offset || 'EOL';

            if (isDateRangeSelected && !onScroll || isDateChanged) {
                store.dispatch(commonActions.setAccountTransactions(
                    accountId, transactions.transactions, newOffset,
                ));
            }
            else {
                store.dispatch(commonActions.appendTransactions(
                    accountId, transactions.transactions, newOffset,
                ));
            }
        } catch (e) {
            !this.cancelFetch && this.setState({isLoading: false});
            throw e;
        }
    }
    onScroll() {
        const {isLoading} = this.state;
        const {activeAccountId} = this.props;
        if (!activeAccountId) return;
        const {store} = this.context;
        const {transactions} = store.getState();
        const accountTransactions = transactions[activeAccountId]
            && transactions[activeAccountId] || {};
        const offset = accountTransactions && accountTransactions.offset || '';
        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100) &&
            !isLoading && offset !== 'EOL'
        ) {
            this.fetchTransactions(this.props.activeAccountId, this.props.startDate,
                this.props.endDate, true);
        }
    }
    render() {
        const {store} = this.context;
        const {transactions} = store.getState();
        const {accounts, activeAccountId} = this.props;
        if (!activeAccountId) return null;
        const index = accounts.findIndex(item => item.id === activeAccountId);
        const activeAccount = accounts[index];
        const accountTransactions = transactions[activeAccountId]
            && transactions[activeAccountId].transactions || [];
        return (
            <div className={'Transactions-account'}>
                <div className={'Transactions-title'}>
                    <div
                        className={'Account-item-stripe'}
                        style={{
                            transition: 'background-color 350ms',
                            backgroundColor: TokenChart.getColor(index),
                        }} />
                    <img src={activeAccount.bankLogo || ironLogo} />
                    <div className={'Transactions-info'}>
                        <span>{activeAccount.name}</span>
                        <span className={'Transactions-count'}>
                            {getPluralizedString(accountTransactions.length, 'Transaction')}
                        </span>
                    </div>
                </div>
                <ol className={'Transactions-list'}>
                    {accountTransactions.map(t => {
                        const date = (new Date(parseInt(t.createdAtMs)))
                            .toUTCString()
                            .replace('GMT', 'UTC');
                        const amount = t.amount.value;
                        const currencyString =
                            `${t.type === 'CREDIT'
                                ? '+'
                                : '-'}${currencySymbols[t.amount.currency] || ''}`;
                        return (
                            <li className={'Transaction-item'} key={t.id}>
                                <div className={'Transaction-info'}>
                                    <span className={'Transaction-desc'}>{t.description}</span>
                                    <br />
                                    <span>{date}</span>
                                    <span>{t.metadata && t.metadata.merchantName}</span>
                                    <span>{t.metadata && t.metadata.merchantCategoryCode}</span>
                                </div>
                                <div className={'Transaction-amount'} style={{
                                    color: t.type === 'CREDIT'
                                        ? 'green'
                                        : 'red'}}>
                                    {currencyString}
                                    <FormattedNumber
                                        value={Math.abs(amount || 0)}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2} />
                                </div>
                            </li>
                        );
                    })}
                    {this.state.isLoading && (
                        <li className={'Transaction-item'}>
                            <div className={'Transaction-info'}>
                                <span className={'Transaction-desc'}>Loading...</span>
                            </div>
                        </li>
                    )}
                </ol>
            </div>
        );
    }
}

TransactionsList.propTypes = {
    accounts: PropTypes.array,
    activeAccountId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

TransactionsList.contextTypes = {
    store: PropTypes.object,
};

const Transactions = ({
    accounts,
    activeAccountId,
    onActiveAccountChange,
    onDateChange,
    startDate,
    endDate,
    onStartDateSelect,
    selectedStartDate }) => (
    <div className={'Transactions-panel'}>
        <div className={'Transactions-panel-title'}>
            <h3>
                Transactions
            </h3>
            {accounts && accounts.length ? <TransactionsListControls
                accounts={accounts}
                activeAccountId={activeAccountId}
                onActiveAccountChange={onActiveAccountChange}
                onDateChange={onDateChange}
                onStartDateSelect={onStartDateSelect}
                selectedStartDate={selectedStartDate} /> : null}
        </div>
        <div className={'Transactions-content'}>
            <TransactionsList
                accounts={accounts}
                activeAccountId={activeAccountId}
                startDate={startDate}
                endDate={endDate} />
        </div>
    </div>
);

Transactions.propTypes = {
    accounts: PropTypes.array,
    activeAccountId: PropTypes.string,
    onActiveAccountChange: PropTypes.func,
    onDateChange: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    onStartDateSelect: PropTypes.func,
    selectedStartDate: PropTypes.string,
};

export default Transactions;
