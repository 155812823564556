import {combineReducers} from 'redux';

const accounts = (state = [], action) => {
    switch (action.type) {
        case 'SET_ACCOUNTS':
            return action.accounts || [];
        default:
            return state;
    }
};

const transactions = (state = {}, action) => {
    switch (action.type) {
        case 'APPEND_TRANSACTIONS':
            if (!state[action.accountId]) {
                return {
                    ...state,
                    [action.accountId]: {
                        transactions: action.transactions,
                        offset: action.offset,
                    },
                };
            } else {
                return {
                    ...state,
                    [action.accountId]: {
                        ...state[action.accountId],
                        transactions: [
                            ...state[action.accountId].transactions,
                            ...action.transactions,
                        ],
                        offset: state[action.accountId].offset === action.offset
                            ? state[action.accountId].offset : action.offset,
                    },
                };
            }
        case 'SET_ACCOUNTS_TRANSACTIONS':
            return {
                ...state,
                [action.accountId]: {
                    ...state[action.accountId],
                    transactions: [
                        ...action.transactions,
                    ],
                    offset: state[action.accountId].offset === action.offset
                        ? state[action.accountId].offset : action.offset,
                },
            };
        case 'SET_ACCOUNTS':
            return {};
        default:
            return state;
    }
};

const activeCurrency = (state = '', action) => {
    switch (action.type) {
        case 'SET_ACTIVE_CURRENCY':
            return action.currency;
        case 'SET_ACCOUNTS':
            if (!action.accounts.length) return '';
            return action.accounts.find(acc =>
                acc.balance.current.currency === state,
            ) ? state
                : action.accounts[0].balance.current.currency;
        default:
            return state;
    }
};

const activeAccountId = (state = '', action) => {
    switch (action.type) {
        case 'SET_ACTIVE_ACCOUNT':
            return action.accountId;
        case 'SET_ACCOUNTS':
            if (!action.accounts.length) return '';
            if (!state) return action.accounts[0].id;
            return action.accounts.find(acc =>
                acc.id === state,
            ) ? state
                : action.accounts[0].id;
        default:
            return state;
    }
};

const tokenData = (state, action) => {
    if (!state) {
        if (localStorage.state !== undefined
            && JSON.parse(localStorage.state).tokenData !== undefined) {
            state = JSON.parse(localStorage.state).tokenData;
        } else {
            state = {};
        }
    }
    switch (action.type) {
        case 'SET_TOKEN_DATA':
            localStorage.state = JSON.stringify({
                tokenData: action.tokenData,
            });
            return action.tokenData || {};
        case 'SET_TOKEN_ID':
            localStorage.state = JSON.stringify({
                tokenData: action.tokenData,
            });
            return {
                ...state,
                tokenId: action.tokenId,
            };
        default:
            return state;
    }
};

const accessError = (state = '', action) => {
    switch (action.type) {
        case 'ACCESS_ERROR':
            return action.errorMessage;
        default:
            return state;
    }
};

const devKey = (state = localStorage.getItem('dk') || '', action) => {
    switch (action.type) {
        case 'SET_DEV_KEY':
            return action?.devKey;
        default:
            return state;
    }
};

const customSource = (state = {}, action) => {
    switch (action.type) {
        case 'SET_WEB_APP_ENABLED':
            return {
                ...state,
                webAppEnabled: action.webAppEnabled,
            };
        case 'SET_SOURCE_BANK_ID':
            return {
                ...state,
                source: {
                    bankId: action.bankId,
                },
            };
        case 'SET_MEMBER_TYPE':
            return {
                ...state,
                memberType: action.memberType,
            };
        case 'SET_RECEIVE_CALLBACK':
            return {
                ...state,
                receiveCallback: action.receiveCallback,
            };
        case 'SET_SELECTED_BANK_INFO':
            return {
                ...state, selectedBank: action.bankInfo,
            };
        case 'SET_CREDENTIALS':
            return {
                ...state, credentials: action.credentials,
            };
        default:
            return state;
    }
};

const app = combineReducers({
    devKey,
    accounts,
    transactions,
    activeCurrency,
    activeAccountId,
    tokenData,
    accessError,
    customSource,
});

export default app;
