import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

class Credentials extends Component {
    render() {
        const {
            setCredentials,
            credentialsValue,
            requiredCredentials,
            onKeyDown,
        } = this.props;

        return (
            <>
                { requiredCredentials &&
                    <span id="Label-span">Credential fields</span>}
                <br />
                {requiredCredentials?.map(data => {
                    return (
                        <TextInput
                            onKeyDown={e => onKeyDown(e)}
                            value={credentialsValue && credentialsValue[data.id] || ''}
                            key={'Credentials-' + data.id}
                            id= {'Credentials-' + data.id}
                            autoComplete="off"
                            onChange={e => setCredentials({ [data.id]: e.target.value })}
                            placeholder={data.displayName}
                            required />
                    );
                })}
            </>
        );
    }
}

Credentials.propTypes = {
    setCredentials: PropTypes.func,
    credentialsValue: PropTypes.object,
    requiredCredentials: PropTypes.array,
    onKeyDown: PropTypes.func,
};

Credentials.defaultProps = {
    setCredentials: () => {},
    credentialsValue: {},
    requiredCredentials: [],
    onKeyDown: () => {},
};

export default Credentials;
