import React, {Component} from 'react';
import style from './TextInput.css';
import _get from 'lodash/get';
import { SmallSpinner } from './SmallSpinner';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(style);

class TextInput extends Component {

    render() {
        const {
            placeholder,
            onChange,
            id,
            value,
            displaySpinner,
            onKeyDown,
            pattern,
            className,
            autoFocus,
            required,
            autoComplete,
        } = this.props;
        return (
            <div className={cx('floating-placeholder-div')}>
                <input
                    placeholder={placeholder}
                    onChange={onChange}
                    id={id}
                    value={value}
                    onKeyDown={onKeyDown}
                    onFocus={e => {
                        e.target.select();
                        this.setState({
                            [id]: {
                                isInputFocused: true,
                            },
                        });
                    }}
                    className={cx({
                        [className]: !!className,
                    })}
                    pattern={pattern}
                    autoFocus={!!autoFocus}
                    required={!!required}
                    autoComplete={autoComplete}
                    onBlur={() => {
                        this.setState({
                            [id]: {
                                isInputFocused: false,
                            },
                        });
                    }} />
                {
                    _get(this.state, `[${id}].isInputFocused`) && value
                        ?
                        <span>
                            {placeholder}
                        </span>
                        :
                        <span></span>
                }
                { displaySpinner && <SmallSpinner /> }
            </div>
        );
    }
}

TextInput.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    displaySpinner: PropTypes.bool,
    onKeyDown: PropTypes.func,
    pattern: PropTypes.string,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    required: PropTypes.bool,
    autoComplete: PropTypes.string,
};

TextInput.defaultProps = {
    placeholder: 'Enter value',
    id: '',
    value: '',
    displaySpinner: false,
    onKeyDown: () => null,
    onChange: () => null,
    className: '',
    autoFocus: false,
    required: false,
    autoComplete: 'on',
};

export default TextInput;
