import React from 'react';
import {render} from 'react-dom';
import {createStore} from 'redux';
import appState from 'reducers';
import './main.css';
import {TOKEN_ENV} from 'config/constants';
import 'assets/favicon.ico';
import 'assets/finvertex-avatar.png';
import {loadState, saveState, syncLocalStorageVersion} from 'util.js';

if (!global.Intl) { // Polyfill for intl
    require('intl');
    require('intl/locale-data/jsonp/en.js');
}

import App from 'components/App';

// Read data if present in query params to initialize the redux state
const defaultData = {
    tokenData: location.search && JSON.parse(location.search.substring(1).split('&').reduce(
        (acc, o) => {
            const [k, v] = o.split('=');
            return {...acc, [k]: decodeURIComponent(v)};
        }, {},
    ).data),
} || undefined;

syncLocalStorageVersion();

const persistedState = loadState();

const aggregatedState = {
    ...persistedState,
    ...defaultData,
};

let store;
if (TOKEN_ENV === 'local' || TOKEN_ENV === 'dev') {
    // Enable for development
    store = createStore(
        appState,
        aggregatedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
    // Enable for production
    store = createStore(appState, aggregatedState);
}

store.subscribe(() => {
    const state = store.getState();
    saveState({
        accounts: state.accounts,
        transactions: state.transactions,
        activeCurrency: state.activeCurrency,
        activeAccountId: state.activeAccountId,
        tokenData: state.tokenData,
        accessError: state.accessError,
        devKey: state.devKey,
        customSource: state.customSource,
    });
});

render(
    <App store={store} history={history} />,
    document.getElementById('app'),
);
