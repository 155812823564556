import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TokenEnablerButton from 'components/TokenEnablerButton';
import {connect} from 'react-redux';
import actionCreators from 'actions/commonActions';
import {bindActionCreators} from 'redux';
import {showIfLocation} from 'SimpleHistory';
import './Unpaired.css';

const Unpaired = ({
    actions,
    devKey,
    webAppEnabled,
    memberType,
    sourceAccount,
    credentials,
}) => {

    useEffect(() => {
        actions.setMemberType(memberType);
    }, [memberType]);

    const isReady = (webAppEnabled || (!webAppEnabled && sourceAccount &&
            sourceAccount.bankId && true)) || false;
    return (
        <div className="Unpaired">
            <section className="col-2">
                <div className="Account-panel">
                    <h3>Get Started - Connect with Token</h3>
                    <div className="Account-panel-content">
                        <p>
                            Token provides software to banks that allows
                            monetization of PSD2 and mitigates
                            disintermediation and security risks associated
                            with granting access to Third Party Providers.
                        </p>
                        { isReady &&
                            <TokenEnablerButton
                                devKey={devKey}
                                memberType={memberType}
                                webAppEnabled={webAppEnabled}
                                sourceAccount={sourceAccount}
                                onError={err => actions.setAccessError(err)}
                                text="LINK WITH TOKEN"
                                credentials={credentials}
                            />
                        }
                    </div>
                </div>
            </section>
            <section className="col-2">
                <div className="Account-panel Account-panel--disabled">
                    <h3>Account Help</h3>
                    <div className="Account-panel-content">
                        <p>
                            Established in 2000, we are your insurance expert
                            commited to providing peace of mind to all kinds
                            of borrowers by helping them obtain and manage a
                            insurance.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

Unpaired.propTypes = {
    actions: PropTypes.object.isRequired,
    devKey: PropTypes.string,
    webAppEnabled: PropTypes.bool.isRequired,
    memberType: PropTypes.string.isRequired,
    sourceAccount: PropTypes.object,
    credentials: PropTypes.object,
};

Unpaired.defaultProps = {
    webAppEnabled: true,
    memberType: 'type1',
};

const mapStateToProps = state => {
    return {
        devKey: state.devKey,
        webAppEnabled: state.customSource.webAppEnabled,
        sourceAccount: state.customSource.source,
        memberType: state.customSource.memberType,
        credentials: state.customSource.credentials,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
};

export default showIfLocation(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Unpaired));
